<template>
  <div class="text-left" style="font-size: 11px">
    <el-form :model="form" :label-position="'top'" ref="form" size="small">
      <el-form-item prop="username" :label="$t('ifm.username')" :rules="rules.req">
        <el-input v-model="form.username" clearable @keyup.enter.native="loginHandler"/>
      </el-form-item>
      <el-form-item prop="password" :label="$t('ifm.psw')" :rules="rules.req">
        <el-input v-model="form.password" clearable type="password" show-password @keyup.enter.native="loginHandler"/>
      </el-form-item>
      <el-form-item prop="checkTa" label="" :rules="rules.reqCheck" class="checkboxClass">
        <div class="checkboxContents">
          <div clss="item" style="padding-right: 10px">
            <el-checkbox v-model="form.checkTa"></el-checkbox>
          </div>
          <div class="item term">
            <p>Please make sure you have read and agree to the 
              <a href="Terms of Use.pdf" target="_blank" class="underline">Terms of Use</a> and 
              <a href="SaaS Agreement.pdf" target="_blank" class="underline">Service Agreement</a>
            </p>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div class="bottom">
      <div>
        <el-button class="loginBtn" type="success" @click="loginHandler" size="small">{{ $t("btn.signIn") }}</el-button>
      </div>
      <div>
        <el-link :underline="false" class="link" @click="forgetHandler">Forgot Password?</el-link>
      </div>
    </div>
  </div>
</template>
<script>
import { forgetPwd, checkCode } from "@/services/user";
import { setToken, setCheckTs, getCheckTs } from "../utils/auth";

export default {
  name: 'LoginCustomerAndJPUTStaff',
  data() {
    return {
      form: {
        username: "",
        password: "",
        checkTa: null
      },
      rules: {
        req: {
          required: true,
          message: this.$t('tips.sr'),
          trigger: ['change', 'blur'],
        },
        reqCheck: {
          required: true,
          message: 'You are required to agree to Terms of Use and Service Agreement before you can login',
          trigger: ['change', 'blur'],
        }
      },
    }
  },
  watch: {
    'form.username': function (newVal) {
      const checkTs = getCheckTs(newVal);
      if (checkTs == 'true') {
        this.form.checkTa = true;
      } else {
        this.form.checkTa = null;
      }
    },
    'form.checkTa': function (newVal) {
      if (newVal === false) {
        this.form.checkTa = null;
      }
    }
  },
  methods: {
    loginHandler() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('load', true )
          const user = { username: this.form.username, password: this.form.password }
          this.$store.dispatch("goLogin", user).then((res) => {
            if (res.token && res.twoFactorEnabled == "false") {
              this.$message.success(this.$t("status.success"));
              setToken(res.token);
              this.$store.dispatch("getUserInfo").then((url) => {
                this.$router.push(url);
              });
              setCheckTs(this.form.username);
            } else {
              this.$prompt(this.$t("ifm.pevc"), {
                confirmButtonText: this.$t("btn.comfirm"),
                cancelButtonText: this.$t("btn.cancel"),
                inputPattern: /\S/,
                inputErrorMessage: this.$t("tips.sr"),
                beforeClose: (action, instance, done) => {
                  if (action == "confirm") {
                    checkCode(instance.inputValue).then((resA) => {
                      if (resA.code == 200) {
                        this.$message.success(this.$t("status.success"));
                        done();
                        setToken(resA.token);
                        this.$store.dispatch("getUserInfo").then((url) => {
                          this.$router.push(url);
                        });
                        setCheckTs(this.form.username);
                      } else {
                        this.$message.error(this.$t("ifm.error"));
                      }
                    });
                  } else {
                    done();
                  }
                },
              });
            }
          }).finally(() => {
            this.$emit('load', false )
          });
        } 
      });
    },
    forgetHandler() {
      this.$prompt("Please input your email", {
        confirmButtonText: this.$t("btn.comfirm"),
        cancelButtonText: this.$t("btn.cancel"),
        inputPattern: /@/,
        inputErrorMessage: "Email format error",
        beforeClose: (action, instance, done) => {
          if (action == "confirm") {
            forgetPwd({ email: instance.inputValue }).then((res) => {
              if (res.code == 200) {
                this.$message.success(res.msg ?? this.$t("status.success"));
                done();
                this.router.push("/");
              } else {
                this.$message.ElMessage.error(this.$t("ifm.error"));
              }
            });
          } else {
            done();
          }
        },
      });
    },
  }
}
</script>
<style scoped lang="scss">
::v-deep .el-form-item__label {
  font-size: 11px !important;
  line-height: 14px;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
}
::v-deep .el-form-item__error {
  font-size: 11px !important;
  padding-top: 0px !important;
}
::v-deep .el-form-item {
  margin-bottom: 14px;
}
::v-deep .el-form-item__content {
  line-height: 36px;
}
.checkboxClass ::v-deep .el-form-item__content {
  line-height: 16px;
}
.checkboxContents{
  width: 100%;
  display: flex;
  justify-content: start;

  .item {
    display: inline-block;
    vertical-align: top;
  }
}
::v-deep .el-checkbox__label  {
  font-size: 10px !important;
  line-height: 14px;
  color: #82889C;
  font-weight: 400;
}
.term {
  font-size: 10px; 
  line-height: 13px;
}
.underline {
  text-decoration: underline;
}
.bottom {
  text-align: center;
  padding: 10px 0px;
}
.loginBtn {
  width: 200px;
}
.link {
  font-size: 11px;
  color: #82889C;
  font-weight: 400;
  padding: 10px 0;
}
</style>